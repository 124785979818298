svg.main-logo{
    width: 100px;
    height: 80px;
    display: block;
    margin: auto;
}
nav.nav-panel {
    display: flex;
    justify-content: flex-start;
    line-height: 22px;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
    color: #393b45;
    margin-top: 20px;
}
nav.nav-panel a{
    text-decoration: none;
    color: #393b45;
}
nav.nav-panel span{
    margin: 0 5px;
}

.posted-time{
    font-size: 12px;
    color: #393b45;
}
.page-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.terms-link{
  color: #393b45;
}
hr{
    border: none;
    border-bottom: solid 1px lightgrey;
    width: 100%;
}
.social-media a{
    display: block;
    margin: 5px 10px;
}
.social-media svg{
    width: 24px;
    height: 24px;
    fill: #393b45;
}
.social-media {
  display: flex;
}
.articles-link{
  text-decoration: none;
  color: black;
}
.page-container{
  max-width: 600px;
  margin: auto;
}
body,
textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
