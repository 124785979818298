.center-element {
  margin: auto;
}

.center-text {
  text-align: center;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.pac-container {
  box-shadow: unset;
  border: solid 1px black;
  box-shadow: 2px 2px 4px grey;
  border-radius: 20px;
}

.pac-matched {}

.pac-item {
  padding: 5px;
  font-size: 14px;
}

.pac-item-query {
  font-size: 14px;
}

hr {
  border: none;
  border-bottom: solid 1px #c7c7c7;
}

html, body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.cut-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.front-main-container {
  background-color: #F9F5Fa;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 980px) {
  .background-main-container {
    background-color: lightgray;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .front-main-container {
    background-color: white;
    position: fixed;
    width: 600px;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 4px grey;
    border-radius: 12px;
    border: solid 1px grey;
    background-color: #F9F5Fa;
  }
  .desktop-scroll {
    height: 100%;
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}